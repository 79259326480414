import { useElementSize } from '@kaliber/use-element-size'

import styles from './QuoteSlanted.css'

export function QuoteSlanted({ quote, author, jobRole, layoutClassName }) {
  const { ref: elementRef, size } = useElementSize()
  const height = Math.min(size.height, size.width)
  const SLANT_RATIO = 3 / 5

  const style =  {
    shapeOutside: 'polygon(100% 0, 0 100%, 100% 100%)',
    height: height + 'px',
    width: height * SLANT_RATIO + 'px',
  }

  return (
    <figure className={cx(styles.component, layoutClassName)} ref={elementRef}>
      <div className={styles.shapeOutside} {...{ style }} />
      {quote && (
        <blockquote className={styles.quote}>
          <q>{quote}</q>
        </blockquote>
      )}
      {(author || jobRole) && (
        <figcaption className={styles.caption}>
          {author && (
            <span className={styles.name}>
              {author}
            </span>
          )}
          {jobRole && (
            <span className={styles.jobRole}>
              {jobRole}
            </span>
          )}
        </figcaption>
      )}
    </figure>
  )
}
