import { QuoteSlanted } from '/features/buildingBlocks/QuoteSlanted'
import { ImageCover } from '/features/buildingBlocks/Image'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { ScrollAnimation } from '/features/buildingBlocks/ScrollAnimation'
import { triggers }  from '@kaliber/scroll-progression'

import styles from './QuoteWithImage.css'

export function QuoteWithImage({ quote, author, jobRole, image, color }) {
  const backgroundColor = createColorClassName(color)
  const triggerScrollParentEnd = {
    start: { scrollParent: triggers.bottom() }
  }
  return (
    <div className={cx(styles.component, color && styles[backgroundColor])}>
      <ContainerMd>
        <div className={styles.layout}>
          {quote && (
            <QuoteSlanted
              {...{ quote, author, jobRole }}
              layoutClassName={styles.quote}
            />
          )}
          <div className={styles.imageAndLines}>
            {image?.asset ?
              <div className={styles.imageAndLineLayout}>
                <ScrollAnimation
                  layoutClassName={styles.backgroundLine}
                  width={498}
                  height={370}
                  path="M1.62397 369.548 217.995 1.2373h279.348L280.014 369.548H1.62397Z"
                  triggers={triggerScrollParentEnd}
                />
                <div className={styles.coverImage}>
                  <ImageCover
                    {...{ image }}
                    aspectRatio={4 / 3}
                    sizes='min(768px, 50vw)'
                  />
                </div>
              </div>
              :
              <div className={styles.placeholdersLayout}>
                <ScrollAnimation
                  layoutClassName={styles.placeholderLine1}
                  width={498}
                  height={370}
                  path="M1.62397 368.686 217.995.375h279.348L280.014 368.686H1.62397Z"
                  triggers={triggerScrollParentEnd}
                />
                <ScrollAnimation
                  layoutClassName={styles.placeholderLine2}
                  width={498}
                  height={370}
                  path="M1.62397 369.548 217.995 1.2373h279.348L280.014 369.548H1.62397Z"
                  triggers={triggerScrollParentEnd}
                />
              </div>
            }
          </div>
        </div>
      </ContainerMd>
    </div>
  )
}
const knownColors = ['white', 'blue', 'lightBlue', 'black', 'gray']

function createColorClassName(value) {
  return knownColors.includes(value) ? `is${capitalizeFirstLetter(value)}` : null
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
