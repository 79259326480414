import { useSpring, animated } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'
import styles from './ScrollAnimation.css'

export function ScrollAnimation({ layoutClassName = undefined, path, width, height, easing = t => t, triggers: triggerOverrides = undefined }) {
  const [{ animatedProgression }, spring] = useSpring(() => ({
    animatedProgression: 0,
    config: { tension: 900, friction: 235, clamp: true }
  }))

  const trackedElementRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.center(), ...triggerOverrides?.start },
    end: { element: triggers.bottom(), scrollParent: triggers.center(), ...triggerOverrides?.end },
    onChange(progress) {
      spring.start({ animatedProgression: easing(progress) })
    }
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div ref={trackedElementRef} className={styles.scrollParent}>
        <Path layoutClassName={styles.path} {...{ animatedProgression, path, width, height }} />
      </div>
    </div>
  )
}

function Path({ animatedProgression, path, width, height, layoutClassName = undefined }) {
  const pathRef = React.useRef(null)
  const [totalLength, setTotalLength] = React.useState(10E9) // Arbitrarily large number

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)

    function handleResize() {
      setTotalLength(pathRef.current?.getTotalLength())
    }
  }, [])

  return (
    <svg
      {...{ width, height }}
      viewBox={`0 0 ${width} ${height}`}
      className={cx(styles.componentPath, layoutClassName)}
    >
      <animated.path
        ref={pathRef}
        fill="none"
        stroke="currentColor"
        strokeWidth='1'
        strokeLinecap="round"
        d={path}
        strokeDashoffset={animatedProgression.to((x) => lerp({ start: totalLength, end: 0, input: x }))}
        strokeDasharray={[totalLength, totalLength].join(' ')}
      />
    </svg>
  )
}
