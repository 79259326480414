import styles from './Container.css'

export function ContainerMd({ children }) {
  return <ContainerBase className={styles.componentMd} {...{ children }} />
}

export function ContainerLg({ children }) {
  return <ContainerBase className={styles.componentLg} {...{ children }} />
}

export function ContainerXl({ children }) {
  return <ContainerBase className={styles.componentXl} {...{ children }} />
}

function ContainerBase({ children, className }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <div className={styles.layout}>
        {children}
      </div>
    </div>
  )
}
